.DateFilter_Container{
    background-color:var(--lightmain);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: fit-content;
    height: calc(fi + 40px);
    z-index: 2  ;
}
.space{
    height: 50px;
    /* background-color: beige; */
}
.DateFilter_Container input{
    background: none;
    border: none;
}
.DateFilter_Container .title_text{
    font-size: 15px;
    font-weight: bold;
    width: calc(100% - 20px);
    margin: 10px 10px;

}
.DateFilter_Container .input_text{
    width: calc(100% - 20px);
    margin: 5px 10px;
    border: 1px solid blue;
    border-radius: 5px;
    padding: 10px 10px;
    font-size: 13px;

}
.DateFilter_Container .input_text span{
    float: right;
}
.DateFilter_Container .date_input{
    float: right;
    background: none;
    border: none;
}
.DateFilter_Container button{
    width: calc(100% - 20px);
    margin: 5px 10px;
    border: 1px solid blue;
    border-radius: 5px;
    padding: 10px 10px;
    font-size: 13px;
    background:none;
}