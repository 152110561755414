.Ledger_Container {
  flex: 1;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  overflow: hidden;
  max-height: 100vh;
}


/* from watchlist */

.Ledger_Container .data_table {
  display: flex;
  /* width: calc(100% - 20px); */
  width: 100%;
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.Ledger_Container .data_table .data_item {
  flex: 1;
  display: inline-flex;
  /* border: 1px solid grey; */
  border-bottom: 1px solid grey;
  padding: 5px 0px;
  width: 100%;
  /* background-color: azure; */
}
.Ledger_Container .data_item .data_script_part {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Ledger_Container .data_item .script_text {
  margin: 0px;
  font-size: 17px;
  font-weight: bold;
}
.Ledger_Container .data_item .script_text span {
  margin: 0px;
  font-size: 10px;
  font-weight: bold;
}
.Ledger_Container .data_item .rate_text {
  margin: 0px;
  font-size: 11px;
  font-weight: bold;
  color: rgb(101, 101, 102);
  display: inline-flex;
}

.Ledger_Container .data_item .rate_text img {
  width: 18px;
  height: 18px;
}

.Ledger_Container .data_item .ltp_text span {
  font-size: 9px;
  color: black;
}
.Ledger_Container .data_item .bscript_text {
  margin: 0px;
  font-size: 10px;
  font-weight: bold;
  /* color: blue; */
}

.Ledger_Container .data_item .expiry_text {
  margin: 0px;
  font-size: 10px;
}
.Ledger_Container .data_item .data_value_part {
  margin: 0px 10px;
  align-items: end;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.Ledger_Container .data_item .value_text {
  /* background-color: brown; */
  margin: 0;
  padding: 0;
  color: rgb(34, 189, 34);
  font-size: 17px;
  font-weight: bold;
  /* height: 40px; */
  border-radius: 5px;
  display: flex;
  /* background-color: aqua; */
  /* justify-content: center; */
  /* align-self: center; */
  /* justify-self: center; */
  /* align-items: center; */
  /* text-align: end; */
}
.Ledger_Container .data_item .brok_text {
  font-size: 10px;
  align-items: center;
  padding: 0px;
  font-weight: bold;
  margin: 0;
}
.Ledger_Container .data_item .add_text {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: bold;
  border-radius: 5px;
  display: flex;
}
.Ledger_Container .data_item .expiry_text {
  font-size: 10px;
  align-items: center;
  /* width: 70px; */
  /* text-align: center; */
  padding: 0px;
  /* margin: 2px 0px; */
  font-weight: bold;
}
.Ledger_Container .data_item .data_lot_part {
  width: 80px;
  margin: 0px;
  margin-left: 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.Ledger_Container .lot_icon {
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  /* background-color: aquamarine; */
}
/* .Ledger_Container .data_item .lot_text {
          
          margin: 0;
          padding: 0;
          width: 80px;
          color: white;
          font-size: 15px;
          font-weight: bold;
          height: 40px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-self: center;
          justify-self: center;
          align-items: center;
        } */
.Ledger_Container .data_item .l_text {
  font-size: 12px;
  align-items: center;
  /* width: 70px; */
  text-align: center;
  padding: 0px;
  margin: 2px 0px;
  font-weight: bold;
}


.Ledger_Container .top_bar{
    display: flex;
    width: calc(100% - 20px);

    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    position: relative;
}
.Ledger_Container .top_bar img{
    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;    
    transform: rotate(180deg);

}
.Ledger_Container .top_bar .title_text{
    font-size: 17px;
    font-weight: bold;
}