.Trades_Container {
  flex: 1;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-height: 100vh;
}
.Trades_Container .over_box {
  width: calc(100% - 40px);
  border: 1px solid green;
  margin: 20px;
  position: relative;
  z-index: 1;
  background-color: white;
}
.Trades_Container .over_box_top {
  display: flex;
  flex-direction: column;
  /* border-bottom: 1px solid blue; */
}
.Trades_Container .over_box .sqoff_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  border: 1px solid var(--main);
  background: none;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  color: var(--main);
}
.Trades_Container .over_box .detail_over_box {
  display: flex;
  flex-direction: column;
}
.Trades_Container .over_box .ob_live_text {
  margin: 7px;
  font-size: 18px;
  font-weight: bold;
}
.Trades_Container .over_box .ob_live_value {
  font-size: 22px;
  margin: 0px 7px;
  font-weight: bold;
  color: rgb(27, 187, 27);
}
.Trades_Container .over_box .ob_book_text {
  font-size: 14px;
  font-weight: bold;
  margin: 4px 7px;
}
.Trades_Container .over_box .ob_book_value {
  font-size: 16px;
  font-weight: bold;
  margin: 0px 7px;
  color: red;
  margin-bottom: 5px;
}
.Trades_Container .over_box .over_box_bottom {
  display: flex;
  flex-direction: column;
}
.Trades_Container .over_box .balance_bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* margin: 2px 0px; */
}
.Trades_Container .over_box .balance_bar_icon {
  /* background-color: aqua; */
  width: 30px;
  height: 30px;
  margin: 0px 10px;
}
.Trades_Container .over_box .balance_bar_text {
  font-weight: bold;
  margin: 0;
}
.Trades_Container .over_box .balance_bar_value {
  margin: 0;
  margin-left: auto;
  margin-right: 10px;
  font-weight: bold;
}

.Trades_Container .over_box .margin_bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* margin: 2px 0px; */
  /* margin: 0; */
  /* margin-bottom: 2px; */
}
.Trades_Container .over_box .margin_bar_icon {
  /* background-color: aqua; */
  width: 30px;
  height: 30px;
  margin: 0px 10px;
}
.Trades_Container .over_box .margin_bar_text {
  font-weight: bold;
  margin: 0;
}
.Trades_Container .over_box .margin_bar_value {
  margin: 0;
  margin-left: auto;
  margin-right: 10px;
  font-weight: bold;
}

/* from watchlist */

.Trades_Container .data_table {
  display: flex;
  /* width: calc(100% - 20px); */
  width: 100%;
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  /* flex: 1; */
  /* height: 70vh; */
  margin-bottom: 35px;
}
.Trades_Container .data_table .data_item {
  flex: 1;
  display: inline-flex;
  /* border: 1px solid grey; */
  border-bottom: 1px solid grey;
  padding: 5px 0px;
  width: 100%;
  /* background-color: azure; */
}
.Trades_Container .data_item .data_script_part {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Trades_Container .data_item .script_text {
  margin: 0px;
  font-size: 17px;
  font-weight: bold;
}
.Trades_Container .data_item .script_text span {
  margin: 0px;
  font-size: 10px;
  font-weight: bold;
}
.Trades_Container .data_item .rate_text {
  margin: 0px;
  font-size: 11px;
  font-weight: bold;
  color: blue;
  display: inline-flex;
}

.Trades_Container .data_item .rate_text img {
  width: 18px;
  height: 18px;
}

.Trades_Container .data_item .ltp_text span {
  font-size: 9px;
  color: black;
}
.Trades_Container .data_item .bscript_text {
  margin: 0px;
  font-size: 10px;
  font-weight: bold;
  color: blue;
}

.Trades_Container .data_item .expiry_text {
  margin: 0px;
  font-size: 10px;
}
.Trades_Container .data_item .data_value_part {
  margin: 0px 10px;
  align-items: end;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.Trades_Container .data_item .value_text {
  /* background-color: brown; */
  margin: 0;
  padding: 0;
  color: rgb(34, 189, 34);
  font-size: 17px;
  font-weight: bold;
  /* height: 40px; */
  border-radius: 5px;
  display: flex;
  /* background-color: aqua; */
  /* justify-content: center; */
  /* align-self: center; */
  /* justify-self: center; */
  /* align-items: center; */
  /* text-align: end; */
}
.Trades_Container .data_item .brok_text {
    font-size: 10px;
    align-items: center;
    padding: 0px;
      font-weight: bold;
      margin: 0;
  }
  .Trades_Container .data_item .add_text {
    
    margin: 0;
    padding: 0;
    font-size: 17px;
    font-weight: bold;
    border-radius: 5px;
    display: flex;

  }
.Trades_Container .data_item .expiry_text {
  font-size: 10px;
  align-items: center;
  /* width: 70px; */
  /* text-align: center; */
  padding: 0px;
  /* margin: 2px 0px; */
    font-weight: bold;
}
.Trades_Container .data_item .data_lot_part {
  width: 80px;
  margin: 0px;
  margin-left: 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.Trades_Container .lot_icon {
  width: 30px;
  height: 30px;
  margin: 0px;
  padding: 0px;
  /* background-color: aquamarine; */
}
/* .Trades_Container .data_item .lot_text {
      
      margin: 0;
      padding: 0;
      width: 80px;
      color: white;
      font-size: 15px;
      font-weight: bold;
      height: 40px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-self: center;
      justify-self: center;
      align-items: center;
    } */
.Trades_Container .data_item .l_text {
  font-size: 12px;
  align-items: center;
  /* width: 70px; */
  text-align: center;
  padding: 0px;
  margin: 2px 0px;
  font-weight: bold;
}
