/* #14877a */
/* #e8fcfa */
/* #818181 */
/* #39d039 */



.Profile_Container{
    width: 100%;
    height: calc(100vh + 50px);
    background-color: var(--lightmain);
    overflow-y: scroll;
    flex: 1;
    max-height: 100vh;
    
}
/* .Profile_Container .back_color_box{
    width: 100%;
    position: absolute;
    z-index: 1;
    height: 150px;
    background-color: var(--main);
    width: 700px;
    height: 700px;
    border-radius: 600px;
    top: -500px;
    left: -175px;
    overflow: hidden;
} */
.Profile_Container .top_box{
    display: flex;
    /* z-index: 6; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Profile_Container .top_box .logo_box{
    z-index: 1;
    width: 220px;
    height: 220px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 150px;
    margin-top: 30px;
}

.Profile_Container .top_box .logo_img{
    width: 150px;
    height: 150px;
}
.Profile_Container .top_box .text_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
}
.Profile_Container .top_box .server_text{
    margin: 0;
    color: blue;
    font-size: 22px;
    font-weight: bold;
    text-transform: capitalize;
    color: var(--green);
}
.Profile_Container .top_box .username_text{
    margin: 0;
    font-size: 17px;
    text-transform: capitalize;
    color: var(--grey);
    font-weight: bold;

}
.Profile_Container .lower_box{
    width: calc(100% - 20px);
    align-items: center;
    justify-content: center;
    margin: 10px ;
    border-radius: 10px;
    background-color: white;
}
.Profile_Container .lower_box .list_div{
    width: calc(100% - 20px);
    margin: 0px 10px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.Profile_Container .lower_box .list_icon{
    margin: 5px;
    width: 30px;
    height: 30px;
} 
.Profile_Container .lower_box .list_text{
    margin-right: auto;
    font-size: 15px;
    font-weight: bold;
}
.Profile_Container .ip{
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 6;
    color: white;
    margin: 0px;
    font-size: 11px;
}
.Profile_Container .lower_box .list_next_icon{
    width: 30px;
    height: 30px;
    transform: rotate(-90deg);
    opacity: .6;
}
.Profile_Container .list_divider{
    height: 1px;
    background-color: rgb(202, 202, 202);
    width: calc(100% - 20px);
    margin: 0px 10px;
}