:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --main : #14877a;
  --lightmain : #e8fcfa;
  --grey : #818181;
  --green: #39d039;
}

.App {
  text-align: center;
  overflow-y: hidden;
  
}


/* #14877a */
/* #e8fcfa */
/* #818181 */
/* #39d039 */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

input:focus-visible {

  box-sizing: border-box;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.p-inputtext:enabled:focus {
  border-color: unset !important;
}

.p-inputtext:enabled:focus {
  box-shadow: unset !important;
}
body{
  padding: 0;
  margin: 0;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; 
}


.greenify-container {
  position: relative;
  overflow: hidden;
}

.greenify-image {
  max-width: 100%;
  height: auto;
  filter: brightness(0) saturate(100%) hue-rotate(120deg) brightness(1.5);
}

body{
  background: white;
  background-color: white;
  overflow: hidden;
}