.Account_Container{
    width: 100%;
    background-color: rgb(236, 236, 236);
    overflow-y: scroll;
    max-height: 100vh;
}
/* .Account_Container .details_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */



.Account_Container .details_box{
    width: calc(100% - 20px);
    align-items: center;
    justify-content: center;
    margin: 10px ;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
}
.Account_Container .detail_title{
    width: calc(100% - 30px );
    margin: 10px 0px;
}


.Account_Container .details_box .detail_item{
    width: calc(100% - 20px);
    /* margin: 0px 10px; */
    /* margin-right: 10px; */
    margin: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.Account_Container .details_box .detail_icon{
    margin: 5px 0px ;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    
} 
.Account_Container .details_box .detail_text{
    margin: 5px;
    margin-right: auto;
    font-size: 15px;
    font-weight: bold;
    
}
.Account_Container .details_box .detail_next_icon{
    width: 30px;
    height: 30px;
}
.Account_Container .detail_divider{
    height: 1px;
    background-color: rgb(202, 202, 202);
    width: calc(100% - 20px);
    margin: 0px 10px;
}



.Account_Container .top_bar{
    display: flex;
    width: calc(100% - 20px);

    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    position: relative;
}
.Account_Container .top_bar img{
    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;    

    transform: rotate(180deg);

}
.Account_Container .top_bar .title_text{
    font-size: 17px;
    font-weight: bold;
}