.Stock_Container {
  flex: 1;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Stock_Container .top_bar {
  display: flex;
  width: calc(100% - 20px);

  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.Stock_Container .top_bar img {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 10px;
  transform: rotate(180deg);
}
.Stock_Container .top_bar .title_text {
  font-size: 17px;
  font-weight: bold;
}

.Stock_Container .script_div {
  flex: 1;
  display: inline-flex;
  /* border: 1px solid grey; */
  border-bottom: 1px solid grey;
  padding: 5px 0px;
  width: 100%;
  /* background-color: azure; */
}
.Stock_Container .script_div .data_script_part {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Stock_Container .script_div .script_text {
  margin: 0px;
  font-size: 17px;
  font-weight: bold;
}
.Stock_Container .script_div .script_text span {
  margin: 0px;
  font-size: 10px;
  font-weight: bold;
}
.Stock_Container .script_div .rate_text {
  margin: 0px;
  font-size: 11px;
  font-weight: bold;
  color: rgb(101, 101, 102);
  display: inline-flex;
}

.Stock_Container .script_div .rate_text img {
  width: 18px;
  height: 18px;
}

.Stock_Container .script_div .ltp_text span {
  font-size: 9px;
  color: black;
}
.Stock_Container .script_div .bscript_text {
  margin: 0px;
  font-size: 10px;
  font-weight: bold;
  /* color: blue; */
}

.Stock_Container .script_div .expiry_text {
  margin: 0px;
  font-size: 10px;
}
.Stock_Container .script_div .data_value_part {
  margin: 0px 10px;
  align-items: end;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.Stock_Container .script_div .value_text {
  /* background-color: brown; */
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: bold;
  /* height: 40px; */
  border-radius: 5px;
  display: flex;
  /* background-color: aqua; */
  /* justify-content: center; */
  /* align-self: center; */
  /* justify-self: center; */
  /* align-items: center; */
  /* text-align: end; */
}
.Stock_Container .script_div .brok_text {
  font-size: 13px;
  align-items: center;
  padding: 0px;
  font-weight: bold;
  margin: 0;
  color: rgb(34, 189, 34);
  display: inline-flex;
}
.Stock_Container .script_div .brok_text img {
  width: 20px;
  height: 20px;
}
.Stock_Container .script_div .add_text {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: bold;
  border-radius: 5px;
  display: flex;
}
.Stock_Container .script_div .expiry_text {
  font-size: 10px;
  align-items: center;
  /* width: 70px; */
  /* text-align: center; */
  padding: 0px;
  /* margin: 2px 0px; */
  font-weight: bold;
}
.Stock_Container .script_div .data_bs_part {
  /* width: 80px; */
  margin: 0px;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--main);
  height: 40px;
  width: 40px;
  display: flex;
  align-self: center;
  justify-self: center;
  border-radius: 25px;
  margin: 0px 10px;

}
.Stock_Container .script_div .data_bs_text {
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  color: var(--main);
  font-size: 18px;
  font-weight: bold;
}
.Stock_Container .lot_icon {
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  /* background-color: aquamarine; */
}
/* .Stock_Container .script_div .lot_text {
              
              margin: 0;
              padding: 0;
              width: 80px;
              color: white;
              font-size: 15px;
              font-weight: bold;
              height: 40px;
              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-self: center;
              justify-self: center;
              align-items: center;
            } */
.Stock_Container .script_div .l_text {
  font-size: 12px;
  align-items: center;
  /* width: 70px; */
  text-align: center;
  padding: 0px;
  margin: 2px 0px;
  font-weight: bold;
}


.Stock_Container .high_low_div{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid grey;
}
.Stock_Container .high_low_div div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
}
.Stock_Container .high_low_div .hl_title{
    font-size: 15px;
    font-weight: bold;
    color:var(--main);
    margin: 10px;
    margin-bottom: 5px;
}

.Stock_Container .high_low_div .hl_text{
    font-size: 15px;
    font-weight: bold;
    /* color: blue; */
    margin: 0;
    margin-bottom:  10px;
}

.Stock_Container .market_pending_div{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.Stock_Container .market_pending_div div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
}
.Stock_Container .market_pending_div button{
    margin: 10px;
    border:  1px solid grey;
    background-color: var(--main);
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

.Stock_Container .qty_box{
    width: calc( 100% - 40px);
    border-radius: 5px ;
    border: 1px solid grey;
    margin: 5px;
}
.Stock_Container  .qty_text{
    margin: 5px;

}
.Stock_Container  .qty_value{
    font-size: 15px;
    margin: 5px;
    margin-top: 0px;
    background:none;
    width: calc(100% - 20px);
    border: none;
}


.Stock_Container .buy_sell_div {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid black;
}

.Stock_Container .buy_sell_div div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
}
.Stock_Container .buy_sell_div button{
    margin: 10px;
    border:  1px solid grey;
    background-color:var(--main);
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    /* padding: 5px 0px; */
}
.Stock_Container .buy_sell_div button p{
    margin: 0px;
    padding: 0px 30px;
    font-size: 16px;
    font-weight: bold;
    
}
.Stock_Container .buy_sell_div button p:first{
    margin: 15px;
}

.Stock_Container .qty_div{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid black;
}
.Stock_Container .qty_div .qty_div_item{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
}
.Stock_Container .qty_div .qty_div_item h6{
  margin: 0;
  font-size: 17px;
  color: var(--grey);
}
.Stock_Container .qty_div .qty_div_item p{
  margin: 0;
  font-size: 15px;
  color: black;
  font-weight: bold;
}


.Stock_Container .position_bs_div{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid black;
}
.Stock_Container .position_bs_div .position_bs_div_item{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
}
.Stock_Container .position_bs_div .position_bs_div_item h6{
  margin: 0;
  font-size: 17px;
  color: var(--grey);
}
.Stock_Container .position_bs_div .position_bs_div_item p{
  margin: 0;
  font-size: 15px;
  color: black;
  font-weight: bold;
}

