.Nav_Container{
    flex: 1;
    /* background-color: aquamarine; */
    display: inline-flex;
    /* position: sticky; */
    position: absolute;
    bottom: 0px;
    /* width: calc( 100% - 20px); */
    width: 100%;
    align-items: center;
    justify-content: space-around;
    /* margin: 0px 10px; */
    padding: 0px 10px;
    border-top: 1px solid var(--main);
    background-color: white;
    
    
}
.Nav_Container .nav_div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2px 0px;

}
.Nav_Container .nav_img{
    height: 20px;
    /* height: 70px; */
    max-width: 30px;
    margin: 0;
}
.Nav_Container .nav_text{
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    
}

.Nav_Container .nav_selected{
    background-color: var(--main);
    /* position: absolute; */
    /* top: -15px; */
    margin-top: -20px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

}