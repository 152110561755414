.SiTp_Container {
  flex: 1;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.back_color_box{
    width: 100%;
    position: absolute;
    z-index: 1;
    height: 120px;
    background-color: var(--main);
    top: 0;  
  }
.SiTp_Container .over_box {
  z-index: 5;
  width: calc(100% - 40px);
  border: 1px solid var(--main);
  margin: 20px;
  margin-top: 30px;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: white;
  height: 100px;
}
.SiTp_Container .over_box .over_box_div {
  flex: 1;
  display: flex;
  margin: 0;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px;
}
.SiTp_Container .over_box .obd_text {
  margin: 0;
  font-weight: bold;
  margin-bottom: 10px;
}
.SiTp_Container .over_box .obd_value {
  margin: 0;
  font-weight: bold;
}

/* from watchlist */

.SiTp_Container .data_table {
  display: flex;
  /* width: calc(100% - 20px); */
  width: 100%;
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
}
.SiTp_Container .data_table .data_item {
  flex: 1;
  display: inline-flex;
  /* border: 1px solid grey; */
  border-bottom: 1px solid grey;
  padding: 5px 0px;
  width: 100%;
  /* background-color: azure; */
}
.SiTp_Container .data_item .data_script_part {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.SiTp_Container .data_item .script_text {
  margin: 0px;
  font-size: 17px;
  font-weight: bold;
}
.SiTp_Container .data_item .script_text span {
  margin: 0px;
  font-size: 10px;
  font-weight: bold;
}
.SiTp_Container .data_item .rate_text {
  margin: 0px;
  font-size: 11px;
  font-weight: bold;
  color: blue;
  display: inline-flex;
}

.SiTp_Container .data_item .rate_text img {
  width: 18px;
  height: 18px;
}

.SiTp_Container .data_item .ltp_text span {
  font-size: 9px;
  color: black;
}
.SiTp_Container .data_item .bscript_text {
  margin: 0px;
  font-size: 10px;
  font-weight: bold;
  color: blue;
}

.SiTp_Container .data_item .expiry_text {
  margin: 0px;
  font-size: 10px;
}
.SiTp_Container .data_item .data_value_part {
  margin: 0px 10px;
  align-items: end;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.SiTp_Container .data_item .value_text {
  /* background-color: brown; */
  margin: 0;
  padding: 0;
  color: rgb(34, 189, 34);
  font-size: 17px;
  font-weight: bold;
  /* height: 40px; */
  border-radius: 5px;
  display: flex;
  /* background-color: aqua; */
  /* justify-content: center; */
  /* align-self: center; */
  /* justify-self: center; */
  /* align-items: center; */
  /* text-align: end; */
}
.SiTp_Container .data_item .brok_text {
  font-size: 10px;
  align-items: center;
  padding: 0px;
  font-weight: bold;
  margin: 0;
}
.SiTp_Container .data_item .add_text {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: bold;
  border-radius: 5px;
  display: flex;
}
.SiTp_Container .data_item .expiry_text {
  font-size: 10px;
  align-items: center;
  /* width: 70px; */
  /* text-align: center; */
  padding: 0px;
  /* margin: 2px 0px; */
  font-weight: bold;
}
.SiTp_Container .data_item .data_lot_part {
  width: 80px;
  margin: 0px;
  margin-left: 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.SiTp_Container .lot_icon {
  width: 30px;
  height: 30px;
  margin: 0px;
  padding: 0px;
  /* background-color: aquamarine; */
}
/* .SiTp_Container .data_item .lot_text {
        
        margin: 0;
        padding: 0;
        width: 80px;
        color: white;
        font-size: 15px;
        font-weight: bold;
        height: 40px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-self: center;
        justify-self: center;
        align-items: center;
      } */
.SiTp_Container .data_item .l_text {
  font-size: 12px;
  align-items: center;
  /* width: 70px; */
  text-align: center;
  padding: 0px;
  margin: 2px 0px;
  font-weight: bold;
}

.SiTp_Container .menu_bar {
  width: 100%;
  flex: 1;
}
.SiTp_Container .menu_bar .inter_menu_bar {
  width: calc(100% - 20px);

  margin: 10px 10px;
  display: inline-flex;
  justify-content: space-around;
  background-color: var(--lightmain);
}
.SiTp_Container .menu_bar .menu_box {
  display: inline-flex;
  /* width: calc(100% - 50px); */
  /* background-color: aqua; */
  /* margin: 10px 20px; */
  padding: 0px 5px;
  border-bottom: 0px solid var(--main);
}
.SiTp_Container .menu_bar .menu_text {
  margin: 5px 5px;
  font-size: 13px;
  font-weight: bold;
}
