.Login_Container{
    width: 100%;
    /* height: 100vh; */
    display: flex;
    justify-self: center;
    align-items: center;
    /* background-color: aqua; */
}
.Login_Container .internal_login{
    width: calc(100% - 30px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* flex: 1; */
    margin: 0px 15px;

}
.Login_Container .login_logo{
    width: 250px ;
    height: 250px;
}
.Login_Container .login_div{
    width: 100%;
    border: 1px solid black;    
    padding: 5px 10px;
    border-radius: 5px;
    margin: 5px 0px;
}
.Login_Container .login_div .login_title{
    font-size: 11px;
    color: grey;
}
.Login_Container .login_div .login_input{
    background: none;
    border: none;
    width: 100%;
    font-size: 14px;
}

.Login_Container  .login_btn{
    width: 100%;
    background-color: blue;
    color: white;
    border-radius: 5px;
    margin: 10px;

    padding: 10px;
    font-size: 15px;
    font-weight: bold;
    border: none;
}

.Login_Container .login_demo_text{
    font-size: 13px;
}
.Login_Container .login_demo_text span{
    font-weight: bold;
}
.Login_Container .space{
    height: 70px;
}




/* for change password */

.ChangePass_Container .top_bar{
    display: flex;
    width: calc(100% - 20px);

    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}
.ChangePass_Container .top_bar img{
    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;    
    transform: rotate(180deg);   
}
.ChangePass_Container .top_bar .title_text{
    font-size: 17px;
    font-weight: bold;
}