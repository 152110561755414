.Watchlist_Container {

  /* height: calc(100vh - 70px); */
  /* overflow: hidden; */
  
  overflow: hidden;
  max-height: 100vh;
}
/* .Watchlist_Container p{
    font-size: 20px;
} */

/* .watchmenu{
    width: 100%;
    background-color: rgb(50, 97, 97);
} */

.Watchlist_Container .search_box {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: calc(100% - 20px);
  margin:  10px;
  /* width: 100%;     */
  justify-content: center;
  align-items: center;
}
.Watchlist_Container .search_box .search_bar {
  /* flex: 8; */
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  border: 1px solid #808080;
  width: 100%;

}
.Watchlist_Container .search_box .search_icon {
  width: 25px;
  height: 25px;
  margin:  5px;
}
.Watchlist_Container .search_box .search_input {
  border: none;
  /* height: auto; */
  width: 100%;
}
.Watchlist_Container .search_box .search_input:hover {
  border: none;
}
.Watchlist_Container .noti_box {
  flex: 1;
  margin: 0px 10px;
}
.Watchlist_Container .noti_box .noti_icon {
  width: 25px;
  height: 25px;
}
.Watchlist_Container .menu_bar {
  width: 100%;
  flex: 1;
}
.Watchlist_Container .menu_bar .inter_menu_bar {
  width: calc(100% - 20px);

  margin: 10px 10px;
  display: inline-flex;
  justify-content: space-around;
  background-color: var(--lightmain);
}
.Watchlist_Container .menu_bar .menu_box {
  display: inline-flex;
  /* width: calc(100% - 50px); */
  /* background-color: aqua; */
  /* margin: 10px 20px; */
  padding: 0px 10px;
  border-bottom: 0px solid var(--main);
}
.Watchlist_Container .menu_bar .menu_text {
  margin: 5px 10px;
  font-size: 15px;
}
.Watchlist_Container .t_header {
  display: inline-flex;
  width: calc(100% - 20px);
  margin: 0px 10px;
}
.Watchlist_Container .t_header p {
  margin: 0px;
  margin: 5px;
  font-weight: bold;
}
.Watchlist_Container .t_header p:first-child {
  margin-right: auto;
}
.Watchlist_Container .t_header p:nth-child(2),
.Watchlist_Container .t_header p:nth-child(3) {
  /* margin: 5px 30px; */
  width: 80px;
  text-align: center;
}
.Watchlist_Container .data_table {
  display: flex;
  width: calc(100% - 20px);
  margin: 0px 10px;  
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  

}
