.Search_Container {
  flex: 1;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Search_Container .top_bar {
  display: flex;
  width: calc(100% - 20px);

  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: 1px solid grey;
}
.Search_Container .top_bar img {
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
}
.Search_Container .top_bar .input_box {
  font-size: 14px;
  /* font-weight: bold; */
  width: 100%;
  background: none;
  border: none;
  margin: 0px 10px;
}

.Search_Container .data_table {
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: calc(100% - 20px);
}
.Search_Container .search_item {
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.Search_Container .char_coin {
    height: 40px;
    width: 40px;
    justify-content: center;
    vertical-align: middle;
    border: 1px solid green;
    border-radius: 25px;
    align-items: center;
    display: flex;
    font-size: 17px;
    font-weight: bold;
    margin: 5px 10px;
    margin-left: 0;
}
.Search_Container .script_text {
    font-size: 20px;
    font-weight: bold;
}
.Search_Container .expiry_text {
    font-size: 12px;
    margin: 10px;
    color: grey;
}
.Search_Container .p_m_icon {
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: 0;
}
.partition{
    font-size: 18px;
    font-weight: bold;
    /* margin: 0px 10px; */
    color: blue;
    background-color: var(--lightmain);
    padding: 0px 10px;
    margin: 0;
}